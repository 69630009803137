import { PatientChangeNotifier } from "./PatientChangeNotifier";
import { autoinject, inject } from "aurelia-framework";
import { ICDService } from "./ICDService";
import { IRiskItem } from "../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import { QuestionnaireResponse } from "resources/classes/FhirModules/Fhir";
import { I18N } from "aurelia-i18n";
import { QuestionnaireService, } from "./QuestionnaireService";
import { IFormSetting } from "resources/classes/IFormSettings";
import { ConfigService } from "./ConfigService";
import { NitTools } from "resources/classes/NursitTools";
import { fhirEnums } from "../classes/fhir-enums";
import { UserService } from "./UserService";
import { FhirService } from "./FhirService";
import { RuntimeInfo } from "../classes/RuntimeInfo";
import { AnalyzerClass } from "./AnalyzerClass";
import { PatientItem } from "../classes/Patient/PatientItem";
import SystemHeaders from "../classes/SystemHeaders";
import { HttpClient } from "aurelia-http-client";

const expr = require("expr-eval");
const moment = require("moment");
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import { forEach } from "lodash";

const environment = require("../../../config/environment.json");

@autoinject
export class AnalyzeService {
    icdService: ICDService;
    i18n: I18N;
    patientChangeNotifier: PatientChangeNotifier;
    userService: UserService;
    fhirService: FhirService;
    lastIcdUpdateTime: Date;
    isUpdatingBundle: boolean;

    public static AnalyzerVersion: string = "V1";

    public get analyzerVersion(): string {
        return AnalyzeService.AnalyzerVersion;
    }

    public static Analyzers: any = {};
    public static PkmsRelevanceStart: number = 25;

    constructor(
        icdService: ICDService,
        i18n: I18N,
        patientChangeNotifier: PatientChangeNotifier,
        userService: UserService
    ) {
        this.fhirService = new FhirService();
        this.icdService = icdService;
        this.i18n = i18n;
        this.patientChangeNotifier = patientChangeNotifier;
        this.userService = userService;
    }

    public validateMark1(patient: PatientItem) {
        return AnalyzeService.ValidateMark1(patient);
    }

    public static ValidateMark1(patient: PatientItem) {
        const m1 = patient.mark(1);

        //#region calculate mark_1
        let mark1Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(
            patient.selectedAdditionalInfo,
            "mark_1",
            true
        );

        if (mark1Item) {
            //#region calculate mark_1
            let isoResponse: any = QuestionnaireService.GetLatestResponseOfType(
                patient,
                QuestionnaireService.__listResult.QIsolationId,
                [
                    fhirEnums.QuestionnaireResponseStatus.completed,
                    fhirEnums.QuestionnaireResponseStatus.amended,
                ]
            );
            let config: IFormSetting = ConfigService.GetFormSettings(
                ConfigService.FormNames.Isolation
            );

            if (config) {
                m1.checked = NitTools.ParseBool(
                    Fhir.QuestionnaireResponse.GetResponseItemValue(mark1Item)
                );
                if (m1.checked) {
                    if (isoResponse && isoResponse.authored) {
                        let age = moment(new Date()).diff(
                            isoResponse.authored,
                            "h"
                        );
                        // if "now" is greater than the expiration mark it as red
                        m1.red = Fhir.Tools.GetMark(
                            patient,
                            1,
                            "red",
                            config.expiration.default
                        ); // age > config.expiration.default; //  config. expirationDate.valueOf() < new Date().valueOf();
                    } else {
                        m1.red = m1.checked;
                    }
                } else {
                    m1.red = false;
                }
            }
            //#endregion

            if (patient.flags && patient.flags[0]) {
                let flag = <any>patient.flags[0];
                let mark = flag.code.coding.find((o) =>
                    o.system.endsWith("mark_1")
                );
                let mark_yellow = flag.code.coding.find((o) =>
                    o.system.endsWith("mark_1_yellow")
                );
                let mark_red = flag.code.coding.find((o) =>
                    o.system.endsWith("mark_1_red")
                );
                if (mark) mark.code = String(patient.mark_1);
                if (mark_yellow)
                    mark_yellow.code = String(patient.mark_10_yellow);
                if (mark_red) mark_red.code = String(patient.mark_1_red);
            }
        }
        //#endregion
    }

    public async validateMarks(patient: PatientItem) {
        await AnalyzeService.ValidateMarks(patient);
    }

    public static async ValidateMarks(patient: PatientItem): Promise<string[]> {
        if (!patient)
            return undefined;

        await ConfigService.LoadConfigOverride(patient?.ward, patient);
        await PatientItem.ReadAndCalculateMarksAndVisitNumber(patient);

        if (ConfigService.UseAssessmentForSignal10) {
            const m10 = patient.mark(10);
            m10.checked = false;
            m10.yellow = false;
            m10.red = false;

            const mark10 = patient.marks.find(o => o.index === 10);
            const assessmentName = patient.getAssessmentName();
            const questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentName);
            const assessment = QuestionnaireService.GetLatestResponseOfType(
                patient,
                questionnaireAssessment.id, // QuestionnaireService.__listResult.QAssessmentId,
                [
                    fhirEnums.QuestionnaireResponseStatus.amended,
                    fhirEnums.QuestionnaireResponseStatus.completed,
                ]
            );

            if (!patient.latestAssessment) {
                mark10.red = true; //  patient.mark_10_red = true;
            } else {
                mark10.checked = true;
            }

            //#region calculate mark_10
            if (assessment) {
                let config: IFormSetting = ConfigService.GetFormSettings(
                    ConfigService.FormNames.Assessment
                );

                //patient.mark_10 = true;
                mark10.yellow = Fhir.Tools.GetMark(patient, 10, "yellow", config.expiration.durations.redAfter, config.expiration.durations.yellowAfter, ConfigService.Debug);
                mark10.red = Fhir.Tools.GetMark(patient, 10, "red", config.expiration.durations.redAfter, config.expiration.durations.yellowAfter, ConfigService.Debug);
            } else {
                mark10.red = true;
            }
            //#endregion
        }

        PatientItem.CalculateMark1(patient);
        PatientItem.CalculateMark10(patient);

        const result = await this.ValidateMark5(patient, false);

        /* DONT EVER DO, because it will result in a loop between signalreiter and patientlist:
            PatientChangeNotifier.Notify(patient, patient.flags);
        instead update the patient-list flags directly: */
        return result;
    }

    /**
     * Validate Mark 5 (bi/biEx) - this will be setting the patient-properties for mark5, but returns the hint-texts to display under the "Signalreiter"
     * @param patient the patient to validate mark_5 for
     */
    public static async ValidateMark5(patient: PatientItem, updateFlagsResourceOnFhir: boolean = false): Promise<string[]> {
        return PatientItem.ValidateMark5(patient, updateFlagsResourceOnFhir);

        //------ NOT TO BE REACHED CURRENTLY!!! -----
        // we will be setting the patient-properties for mark5, but return the hint-texts to display under the "Signalreiter"

        const mark5 = patient.marks.find(o => o.index === 5);
        mark5.checked = true;
        mark5.red = true;
        //let mark5Class = 'active-red'; // default result should be red
        let text: string = '';
        const textArr = [];
        //patient.mark_5_class = mark5Class;

        if (!patient.latestAssessment) {
            patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAssessmentId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        }

        if (!patient.latestAssessment) {
            // return ['no_assessment'];  this hint is not needed because it is added in the signalreiter itself
            return [];
        }

        let bi = await Fhir.QuestionnaireResponse.SeekForAttachedResponse(patient, 'barthelindex', 'BarthelIndex');
        let biEx = await Fhir.QuestionnaireResponse.SeekForAttachedResponse(patient, 'barthelindexEx', 'BarthelIndexEx');

        if (!bi) textArr.push('no_bi');
        if (!biEx) textArr.push('no_biEx');
        if (bi && (['amended', 'completed'].indexOf(bi.status) === -1)) textArr.push('bi_not_validated');
        if (biEx && (['amended', 'completed'].indexOf(biEx.status) === -1)) textArr.push('bi_ex_not_validated');

        if ( // bi and biEx exist and both have been validate: green and exit
            (bi && (['amended', 'completed'].indexOf(bi.status) > -1)) &&
            (biEx && (['amended', 'completed'].indexOf(biEx.status) > -1))
        ) {
            mark5.red = false;
        } else {
            if (bi && biEx) {   // if Barthel-Documents exist, but are NOT validated ..
                /*  see #1388, https://nursiti.plan.io/issues/1388#note-13
                6. Gelb - Ein validiertes BI oder eBI liegt vor aber nicht für den aktuelle Assessment, sondern für irgendeins davor
                    - bei BI keine Änderung bei Sturzrisiko vorhanden. (zeig als Info)
                    - bei EBI keine Änderung bei Verwirrtheit-Delir-Demenz vorhanden. (zeig als Info)

                7. Rot Ein validiertes BI oder eBI liegt vor aber nicht für den aktuelle Assessment, sondern für irgendeins davor
                    - bei BI Änderung bei Sturzrisiko vorhanden. (zeig als Info)
                    - bei EBI Änderung bei Verwirrtheit-Delir-Demenz vorhanden. (zeig als Info)
                 */

                if (['amended', 'completed'].indexOf(patient.latestAssessment.status) > -1) // should be always on latestAssessment, but don't trust anything
                {
                    // .. get the RiskFall-Flag from patient.flags ..
                    const flgFall = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskSturz', false);
                    if (flgFall && typeof flgFall.code !== 'undefined') {
                        const bFlagFallValue = NitTools.ParseBool(flgFall.code);

                        // .. get the RiskVdd-Flag from patient.flags ..
                        const flgVdd = Fhir.Tools.GetOrCreateFlag(patient.flags, 'RiskVdd', false);
                        if (flgVdd && typeof flgVdd.code !== 'undefined') {
                            const bFlagVddValue = NitTools.ParseBool(flgVdd.code);

                            // .. if both exist, bi and biEx have been validated for another assessment before ..
                            if (flgVdd && flgFall) {
                                // .. get the risk_sturz item from assessment
                                const qrItemRiskFall = QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, 'risk_sturz', false);
                                const bItemRiskFall = NitTools.ParseBool(QuestionnaireResponse.GetResponseItemValue(qrItemRiskFall));

                                // .. get the risk_vdd item from assessment
                                const qrItemRiskVDD = QuestionnaireResponse.GetResponseItemByLinkId(patient.latestAssessment, 'risk_vdd', false);
                                const bItemRiskVdd = NitTools.ParseBool(QuestionnaireResponse.GetResponseItemValue(qrItemRiskVDD));

                                // .. compare the assessment-items to the flags items ..
                                if (bItemRiskFall === bFlagFallValue && bItemRiskVdd === bFlagVddValue) {
                                    // .. if they are equal, turn to yellow
                                    // patient.mark_5_class = 'active-yellow';
                                    mark5.yellow = true;
                                    if (bItemRiskFall === bFlagFallValue) textArr.push('risk_sturz_same_values');
                                    if (bItemRiskVdd === bFlagVddValue) textArr.push('risk_vdd_same_value');
                                } else {
                                    // .. or let the default red remain
                                    if (bItemRiskFall != bFlagFallValue) textArr.push('risk_sturz_changed');
                                    if (bItemRiskVdd != bFlagVddValue) textArr.push('risk_vdd_changed');
                                }
                            }
                            /* else {
                                // .. EXIT (stying red) if not both exist, because that means there have never been validated Barthel-documents
                            } */
                        }
                    }
                }
            }
        }

        const flagBackup = JSON.stringify(patient.flags.code.coding);
        let mark5Flag = await Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5');
        mark5Flag.code = 'true';

        let mark5RedFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5_red');
        mark5RedFlag.code = mark5.red ? 'true' : 'false';

        let mark5YellowFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5_yellow');
        mark5YellowFlag.code = mark5.yellow ? 'true' : 'false';

        const tag5System = NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition) + '/tags/mark5';
        let tag5 = FhirService.Tags.get(patient.encounter, 'tags/mark5');
        if (!tag5) {
            // if not existent add it...
            await FhirService.Tags.add(patient.encounter, {
                code: patient.mark_5_class,
                system: tag5System,
                display: textArr.join(',')
            });
        } else {
            // update the existing tag...
            const tagDisplay = textArr.join(',');
            if (tag5.code !== mark5.cssClass || tag5.display !== tagDisplay) {
                // .. if it differs
                tag5.code = mark5.cssClass;
                tag5.display = tagDisplay;

                // update the patientlist..
                await FhirService.Tags.update(patient.encounter, tag5);
            }
        }

        //#region store in flags, maybe store flags to fhir and return the warnings
        if (patient.flags && patient.flags.code && patient.flags.code.coding) {
            if (textArr.length === 0) {
                Fhir.Tools.DeleteFlag(patient.flags, 'mark_5_text');
            } else {
                let mark5TextFlag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'mark_5_text');
                mark5TextFlag.code = textArr.join(',');
            }

            if (updateFlagsResourceOnFhir) {
                const flagCompare = JSON.stringify(patient.flags.code.coding);
                if (flagCompare !== flagBackup) {
                    await Fhir.Rest.Update(patient.flags);
                }
            }
        }

        await PatientItem.ReadAndCalculateMarksAndVisitNumber(patient);

        return textArr;
        //#endregion
    }

    private waitForBundleUpdate(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            if (!this.isUpdatingBundle) {
                resolve();
            } else {
                window.setTimeout(async () => {
                    this.lastIcdUpdateTime = new Date();
                    await this.waitForBundleUpdate();
                    resolve();
                }, 100);
            }
        });
    }

    /**
     * Update the existing ICDs aka conditions for the current assessment. This is called from the formBase on save
     * @param patient the patient to update the Conditions for
     * @param assessment the assessment to update the ICDs for
     */
    async updateIcds(patient: PatientItem,assessment: any): Promise<any[]> {
        const forcedUpdateDelay = 3; // seconds until the next update may run
        const qList = await QuestionnaireService.GetQuestionnaireIds();
        const latestAnamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);

        if (!patient.latestAssessment) {
            console.warn("No assessment for patient found");
            return;
        }

        if (this.lastIcdUpdateTime) {
            let delta = new Date().valueOf() - this.lastIcdUpdateTime.valueOf();
            if (delta < forcedUpdateDelay * 1000) {
                console.debug(
                    `Updating ICDs too fast.. at least ${forcedUpdateDelay} seconds must have passed before new update. You have to wait ${Math.round(
                        (forcedUpdateDelay * 1000 - delta) / 1000
                    )} seconds.`
                );
                console.debug("Called from:", new Error("For Trace").stack);
                return;
            }
        }

        // bundle for condition-interactions
        let bundle: any = {
            entry: [],
            type: 'transaction', // "batch",
            resourceType: "Bundle",
            id: NitTools.Uid(),
        };

        // add remove existing conditions to bundle
        // this.icdService.clear(); // force icd-service to reload existing icds
        let existingConditions = await this.icdService.fetch(assessment.id);

        let calculatedICDItems = await this.icdService.calculateICDCodes(patient, assessment);
        let calculatedICDConditions: any[] = [];

        // convert the icd items into conditions for this assessment/patient
        for (const calculatedIcdItem of calculatedICDItems) {
            const condition = calculatedIcdItem.toCondition();
            condition.subject = { reference: `Patient/${patient.id}` };
            if (FhirService.FhirVersion <= 3) {
                condition["context"] = {
                    reference: `Encounter/${patient.encounterId}`,
                };
            } else {
                condition["encounter"] = {
                    reference: `Encounter/${patient.encounterId}`,
                };
            }

            if (FhirService.FhirVersion <= 3) {
                (<any>condition).clinicalStatus = "active";
                (<any>condition).verificationStatus = ["completed", "amended"].indexOf(assessment.status) > -1 ? "confirmed" : "provisional";
            } else {
                condition.clinicalStatus = {
                    text: "active",
                    coding: [
                        {
                            code: "active",
                            display: "Active",
                            system: "http://hl7.org/fhir/ValueSet/condition-clinical"
                        }
                    ]
                };

                const vStatus = ["completed", "amended"].indexOf(assessment.status) > -1 ? "confirmed" : "provisional";
                condition.verificationStatus = {
                    text: vStatus,
                    coding: [
                        {
                            code: vStatus,
                            display: NitTools.Capitalize(vStatus),
                            system: "http://hl7.org/fhir/ValueSet/condition-ver-status"
                        }
                    ]
                };
            }

            condition.recordedDate = (assessment||latestAnamnesis)?.authored || Fhir.Tools.GetTimeStamp();
            condition.onsetDateTime = Fhir.Tools.GetTimeStamp();
            condition.identifier = [
                {
                    system: `http://nursit-insitute.com/ICDs/icd-item`,
                    value: `${assessment.id}_${calculatedIcdItem.icd}`
                },
            ];

            condition.evidence = [
                {
                    detail: [
                        {
                            reference: `QuestionnaireResponse/${assessment.id}`,
                            display: 'Assessment'
                        }
                    ],
                },
            ];

            if (latestAnamnesis) {
                condition.evidence[0].detail.push({
                    reference: `QuestionnaireResponse/${latestAnamnesis.id}`,
                    display: 'Anamnesis'
                });
            }

            calculatedICDConditions.push(condition);
        }

        // mark all the existing Conditions for delete 1st:
        existingConditions.forEach(
            (condition) => (condition["action"] = "delete")
        );

        // now iterate through the calculated icds and try to find a possibly existing one:
        for (const condition of calculatedICDConditions) {
            let existingItem = existingConditions.find(
                (o) =>
                    o.code &&
                    o.code.coding &&
                    o.code.coding[0] &&
                    o.code.coding[0].system.endsWith("/icd-10") &&
                    o.code.coding[0].code === condition.code.coding[0].code
            );
            if (typeof existingItem !== "undefined") {
                existingItem.onsetDateTime = Fhir.Tools.GetTimeStamp();
                existingItem["action"] = "update";
            } else {
                condition["action"] = "create";
                delete condition.id;
                existingConditions.push(condition);
            }
        }

        let icdItem = QuestionnaireResponse.GetResponseItemByLinkId(
            assessment,
            "_icd_codes_",
            true
        );
        if (icdItem) {
            icdItem.answer = [];
        } else {
            let q = QuestionnaireService.GetQuestionnaireDirect(assessment?.questionnaire);
            console.warn(
                `No item with linkId "_icd_codes_" found in the Questionnaire with name "${q.name}" and title: "${q.title}"`
            );
        }

        // create the bundle entries depending on the temporary "action" property of the conditions
        for (const condition of existingConditions) {
            let m : ('GET'|'POST'|'PUT'|'DELETE') = "DELETE";

            let action = condition["action"];
            delete condition["action"]; // remove 'action' property to be back at fhir compatibility
            if (action === "create") m = "POST";
            else if (action === "update") m = "PUT";

            // set the correct verificationStatus when updating or creating, depending on the assessment status:
            if (action === "update" || action === "create") {
                if (
                    assessment.status === "completed" ||
                    assessment.status === "amended"
                ) {
                    condition.verificationStatus = "confirmed";
                } else {
                    condition.verificationStatus = "provisional";
                }

                // when updating or creating new Condition, set the value in the _icd_codes_ item in the assessment:
                if (icdItem) {
                    icdItem.answer.push({
                        valueCoding: {
                            code: condition.code.coding[0].code,
                            display: condition.code.coding[0].display,
                        },
                    });
                }
            }

            // create a complete bundleEntry as for update/PUT ..
            let bundleEntry : any = {
                fullUrl: `${NitTools.ExcludeTrailingSlash(
                    FhirService.Endpoint
                )}/Condition/${condition.id}`,
                request: {
                    method: m,
                    url: `Condition/${condition.id}`,
                },
                resource: condition,
            };

            // .. then delete unwanted properties
            if (bundleEntry.request.method === "POST") {
                // create without url
                delete bundleEntry.request.url;
                delete bundleEntry.fullUrl;
            } else if (bundleEntry.request.method === "DELETE") {
                // delete without resource
                delete bundleEntry.resource;
            }

            bundle.entry.push(bundleEntry);
        }


        // return bundle.entry;
        // /***************¡
        // update the conditions on the fhir-server
        if (bundle.entry && bundle.entry.length > 0) {
            //await this.waitForBundleUpdate(); // wait if there is already an update running

            this.isUpdatingBundle = true;
            try {
                this.lastIcdUpdateTime = new Date();
                let bundleResult = <any>(
                    await this.fhirService.post(bundle)
                );

                this.lastIcdUpdateTime = new Date();
                if (bundleResult && bundleResult.entry) {
                    let errorItems = bundleResult.entry.filter(
                        (o) =>
                            o.response.status.indexOf("40") > -1 ||
                            o.response.status.indexOf("50") > -1
                    );
                    if (errorItems.length > 0) {
                        let msg = "";
                        errorItems.forEach((o) => {
                            let src = o.response.outcome["issue"][0];
                            msg +=
                                "\n" +
                                src["severity"] +
                                ": " +
                                src["diagnostics"];
                        });

                        console.warn(
                            "Error when updating ICDs in a bundle. Error Items:",
                            msg
                        );
                    }
                }

                return bundle.entry; // bundleResult;
            } catch (e) {
                console.warn(e.message || JSON.stringify(e));
            } finally {
                this.isUpdatingBundle = false;
            }
        }
        // ************/
    }

    public async calculateSpi(
        patient: PatientItem,
        response?: any
    ): Promise<IRiskItem> {
        let version = await this.getAnalyzerVersion(patient, response);
        if (!version) {
            return {
                name: "SPI",
                linkIdSum: "risk_spi_sum",
                linkIdIsRisk: "risk_spi",
                hasError: false,
                error: undefined,
                sum: 0,
                textSum: '',
                textRisk: '',
                buttonText: this.i18n.tr("not_aviable"),
                title: this.i18n.tr("riskofpkms"),
                showButton: false,
                careLevel: 0,
                versorgung: false
            };
        }

        let v: AnalyzerClass = AnalyzeService.GetAnalyzer(version);

        return await v.calculateSpi(patient, response);
    }

    /** Get an AnalyzerClass specific to the given version. Falls back to V1 if none is found to keep the App working.
     * @param {string} version specify the Version of the Analyzer to look for
     * @returns {AnalyzerClass} an instance of the registered AnalyzerClass
     */
    public static GetAnalyzer(version: string): AnalyzerClass {
        if (!AnalyzeService.Analyzers) {
            throw "No Analyzers registered in the AnalyzeService.\nInherit a class from AnalyzerClass and use that Inject()-Method to register it with the Ana.-Service";
        }

        if (!version)
            version = "SEMPA";

        let analyzer: AnalyzerClass = AnalyzeService.Analyzers[version.toUpperCase()];

        if (!analyzer) {
            const fallBack = 'SEMPA';
            console.warn(`Could not find AnalyzerClass with Version "${version}". Using Version "${fallBack}" as fallback` );

            analyzer = AnalyzeService.Analyzers[version];
        }

        if (analyzer) {
            const analysisSetting = ConfigService.GetFormSettings("analysis");
            if (analysisSetting && analysisSetting.settings && analysisSetting.settings["riskFields"]) {
                const rfs = analysisSetting.settings["riskFields"];
                const rf = rfs.find(o => o.analyzers.indexOf(analyzer._version) > -1);
                if (rf)
                    analyzer.riskFieldsSetting = rf;
            }
        }

        if (ConfigService.IsTest && !analyzer) {
            throw (`No Analyzer with version: "${version}" found`);
        }

        return analyzer;
    }

    private updateAnalyzerExtension(patient: PatientItem, assessment: any, version: string) {
        AnalyzeService.UpdateAnalyzerExtension(patient, assessment, version);
    }

    public static UpdateAnalyzerExtension(patient: PatientItem, assessment: any, version: string) {
        if (patient.flags) {
            const flag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'analyzerVersion', true);
            if (flag)
                flag.code = version;
        }

        if (assessment) {
            Fhir.Tools.SetExtension(
                assessment,
                "http://nursit-institute.com/analyzing/AnalyzerVersion",
                version
            );
        }
    }

    public static async GetAnalyzerVersion(patient: PatientItem, assessment?: any) {
        let version;
        if (patient) {
            try {
                if (patient?.ward) {
                    await ConfigService.LoadConfigOverride(patient.ward, patient);
                }

                // try to get the analyzer from the latest Assessment and then from the form settings
                if (assessment) {
                    version = Fhir.Tools.GetExtensionValueFromUrl(assessment, "AnalyzerVersion");
                }

                // if not assessment exists, then take the value from the flags
                if (!version && patient.flags) {
                    let flag = Fhir.Tools.GetOrCreateFlag(patient.flags, 'analyzerVersion', false);
                    if (flag && flag.code)
                        version = flag.code;
                }

                if (!version || version === 'none') {
                    const cfg = ConfigService.GetFormSettings('assessment');
                    version = cfg && cfg.analyzer && cfg.analyzer !== 'none' ? cfg.analyzer : 'epa2.2';
                }

                // get the correct analyzer for GH
                const encounterTypeChoice = PatientItem.GetEncounterChoiceItem(patient);
                if (encounterTypeChoice && encounterTypeChoice.analyzer) {
                    version = encounterTypeChoice.analyzer;
                }

                AnalyzeService.UpdateAnalyzerExtension(patient, assessment, version);

            } catch (e) {
                console.warn("ERROR in GetAnalyzerVersion(): ", e);
            }
        }

        /* if (ConfigService.Debug)
            console.debug(`Using Analyzer: "${version}"`); */

        return version || 'epa2.2';
    }

    public async getAnalyzerVersion(patient: PatientItem, assessment?: any) {
        return await AnalyzeService.GetAnalyzerVersion(patient, assessment);
    }

    private static JSONFiles = {};
    public get jsonFiles() {
        return AnalyzeService.JSONFiles;
    }

    public async calculateFromJson(patient, version, assessment) {
        await ConfigService.LoadConfigOverride(patient?.ward, patient);
        //#region preflight check
        if (!patient) return;
        if (!assessment) assessment = patient.latestAssessment;
        if (!assessment) return;
        version = String(version).toUpperCase();
        //#endregion

        let jsAnalyzer: IAnalyzerJson;
        let obj: any = undefined;
        if (this.jsonFiles[version]) {
            jsAnalyzer = this.jsonFiles[version];
        }
        else {
            const url = `config/analyzers/${version}.json`;
            const response = await new HttpClient().get(url);
            obj = JSON.parse(response.response);
            jsAnalyzer = <IAnalyzerJson>obj.analyzer;
            this.jsonFiles[version] = jsAnalyzer;
        }

        if (!jsAnalyzer || !jsAnalyzer.conditions || jsAnalyzer.conditions.length == 0) return;

        const calcObject = {
            ASSESSMENT: {},
            ANAMNESIS: {},
            INTERNAL: {},
            SUM: NaN
        };

        const risks = {};

        const parser = new expr.Parser({ allowMemberAccess: true, operators: { in: true } });

        const evalSubCondition = (subCondition: IAnalyzerJsonConditionItem, parentName: string) => {
            try {
                const expression = parser.parse(subCondition.if);

                const expressionResult = expression.evaluate(calcObject);
                if (expressionResult) {
                    if (ConfigService.Debug)
                        console.debug(`${parentName}: ${subCondition.if} => ${expressionResult} => +${subCondition.sum}`); // , expression.tokens.map(o=>o.type + ': ' + o.value));
                    return subCondition.sum;
                }

                return undefined;
            }
            catch (e) {
                console.warn(e.message || e);
                return undefined;
            }
        };

        const evalCondition = (condition: IAnalyzerJsonCondition) => {
            try {
                let sum = condition.defaultSum || 0;

                for (const calculation of condition.calculations
                    .filter(o => o.type === "calculation")
                    .sort((a, b) => a.index || 0 - b.index || 0)
                ) {

                    for (const subCondition of calculation.conditions.filter(o => o.if)) {
                        const conditionValue = evalSubCondition(subCondition, condition.name);
                        sum += conditionValue || 0;
                    }
                }
                console.debug(condition.name + " now: " + sum);

                return sum;
            }
            catch (e) {
                console.warn(e.message || e);
                return undefined;
            }
        };

        //#region create the calObject to be uses in expr-eval
        //#region add the Assessment fields
        const qAssessment = QuestionnaireService.GetQuestionnaireDirect(assessment.questionnaire);
        const linkIds = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(qAssessment);
        for (const linkId of linkIds) {
            calcObject.ASSESSMENT[linkId] = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, linkId, undefined);
        }
        //#endregion

        //#region add the Anamnesis fields
        const cfgAnamnesis = ConfigService.GetFormSettings('anamnesis');
        if (cfgAnamnesis && cfgAnamnesis.questionnaireName) {
            const qAnamnesis = QuestionnaireService.GetQuestionnaireByNameDirect(cfgAnamnesis.questionnaireName);
            if (qAnamnesis) {
                const linkIds = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(qAnamnesis);
                const anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qAnamnesis.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                for (const linkId of linkIds) {
                    calcObject.ANAMNESIS[linkId] = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, linkId, undefined);
                }
            }
        }
        //#endregion

        //#region add calculations-Result for the INTERNAL object from conditions with usage=="internal"
        for (const condition of jsAnalyzer.conditions.filter(o => o.usage === "internal" && o.calculations && o.calculations.length > 0)) {
            const sum = evalCondition(condition) || condition.defaultSum || 0;
            calcObject.INTERNAL[String(condition.name).toUpperCase()] = sum;

            console.debug(condition.name + " => " + sum);
        }
        //#endregion

        //#endregion

        //#region now use the calcObject to calculate the risks from conditions with usage=="risk"
        const riskConditions = jsAnalyzer.conditions.filter(o => o.usage === "risk" && o.calculations && o.calculations.length > 0);
        for (const condition of riskConditions) {
            calcObject[condition.name] = evalCondition(condition);

            // a risk-condition should have the results property to map the SUM to the risk(s)
            if (typeof calcObject[condition.name] === "number") {
                const resultCondition = condition.calculations.find(o => o.type === "results"); // locate the condition with type=results
                if (resultCondition && resultCondition.results) {
                    for (const resultSubCondition of resultCondition.results.filter(o => o.if)) {
                        const expression = parser.parse(resultSubCondition.if);
                        const expressionResult = expression.evaluate(calcObject);
                        if (expressionResult) {
                            let c = {
                                text: resultSubCondition.resultText,
                                isRisk: resultSubCondition.isRisk,
                                sum: calcObject[condition.name]
                            };

                            c[condition.isRiskField] = resultSubCondition.isRisk;
                            c[condition.sumRiskField] = calcObject[condition.name];
                            risks[condition.sumRiskField] = {
                                code: String(calcObject[condition.name]),
                                display: this.i18n.tr(resultSubCondition.resultText)
                            };

                            risks[condition.isRiskField] = {
                                valueBoolean: NitTools.ParseBool(resultSubCondition.isRisk)
                            };

                            break;
                        }
                    }
                }
            }
        }
        //#endregion
    }

    /**
     * Refreshes the Analyze-Values for the given patient
     * @param patient - the patient to analyze
     * @param assessment - the assessment to use for analysis
     * @param updateRiskAssessment - a value indicating whether the RiskAssessment for the patient should be created/updated. Defaults to true
     * @param storeRiskAssessment - as value indicating whether the RiskAssessment for the patient should be updated on the fhir server
     */
    public async analyse(
        patient: PatientItem,
        assessment: any,
        updateRiskAssessment: boolean = true,
        storeRiskAssessment: boolean = true
    ): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                await ConfigService.LoadConfigOverride(patient?.ward, patient);
                const assessmentName = PatientItem.GetQuestionnaireName(patient, 'assessment');
                if (assessmentName) {
                    let aQ = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentName);
                    if (aQ) {
                        if ((!assessment || (assessment && assessment.questionnaire && FhirService.FhirVersion > 3 ? true : !assessment.questionnaire.reference.endsWith('/' + aQ.id)))) {
                            assessment = QuestionnaireService.GetLatestResponseOfType(patient, aQ.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                            if (assessment) {
                                QuestionnaireService.__listResult.QAssessmentId = aQ.id;
                            }
                        }
                    }
                }

                let version = await this.getAnalyzerVersion(patient, assessment);
                if (!version) {
                    return Promise.resolve({
                        pkmsIsRisk: false,
                        pkmsSum: 42
                    });
                }

                if (patient.isOffline) {
                    if (ConfigService.Debug) console.debug('Not running analyzer because the patient is marked as offline');
                    return Promise.resolve({
                        pkmsIsRisk: false,
                        pkmsSum: 42
                    });
                }

                let v: AnalyzerClass = AnalyzeService.GetAnalyzer(version);
                /*if (ConfigService.Debug && !ConfigService.IsTest)
                    console.debug(`Running Analyzer: "${version}" for Patient: ${patient.display}`, v);*/

                this.analyzer = v;
                let result;
                try {
                    result = await v.analyse(
                        patient,
                        assessment,
                        updateRiskAssessment,
                        storeRiskAssessment
                    );
                } catch (e) {
                    console.warn(e);
                    result = undefined;
                }

                const tagSystem = NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase) + '/SPI';
                const tag5System = `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/tags/mark5`;

                // only update when the assessment is finalized
                if (assessment && ['completed', 'amended'].indexOf(assessment.status) > -1) {
                    if (result) {
                        /* if (ConfigService.Debug)  {
                            console.debug(`Analysis using Analyzer "${v._version}", assessment: "${v.assessmentName}", anamnesis: "${v.anamnesisName}", got SPI Result:`, result);
                        } */

                        try {
                            if (!ConfigService.IsTest) {
                                let curTag = this.fhirService.tags.get(patient.encounter, '/SPI');
                                if (!curTag) {
                                    await this.fhirService.tags.add(patient.encounter, { system: tagSystem, code: String(result.pkmsSum) });
                                } else {
                                    await this.fhirService.tags.update(patient.encounter, { system: tagSystem, code: String(result.pkmsSum) });
                                }
                            }
                        }
                        catch (e) {
                            console.warn(e.message || e);
                        }


                        //let curTag5 = this.fhirService.tags.get(patient.encounter, tagSystem);
                        //await AnalyzeService.ValidateMark5(patient, false);

                        if (updateRiskAssessment || storeRiskAssessment) {
                            await PatientItem.UpdateCareLevel(patient, patient.careLevel, undefined, patient.careLevelText, patient.careLevelColor, patient.SPI);
                            /*
                            if (!patient.flags) {
                                let flags = <any[]>await this.fhirService.fetch('Flag?encounter=' + patient.encounterId);
                                flags.filter(o => o.status === "active").sort((a, b) => {
                                    return new Date(a.meta.lastUpdated).valueOf() - new Date(b.meta.lastUpdated).valueOf();
                                })

                                patient.flags = flags[0];
                            }
                            */

                            /* if (patient.flags && patient.flags.code && patient.flags.code.coding && patient.flags.code.coding.length > 0) {
                                if (typeof result.pkmsSum !== "undefined") {
                                    let existing = patient.flags.code.coding.find(o => o.system.endsWith('/CareLevel'));
                                    if (!existing) {
                                        existing = {
                                            system: NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader) + '/CareLevel'
                                        }

                                        patient.flags.code.coding.push(existing);
                                    }

                                    existing.code = String(Fhir.Tools.SpiToCareLevel(result.pkmsSum));
                                    existing.display = Fhir.Tools.SpiToString(result.pkmsSum);
                                }

                                await this.validateMarks(patient);
                                if (storeRiskAssessment) await this.fhirService.update(patient.flags);
                            } */

                            if (patient && patient.flags && v.needToStoreFlags) {
                                await this.fhirService.update(patient.flags);
                            }
                        }
                    } else {
                        try {
                            const tag = this.fhirService.tags.get(patient.encounter, tagSystem);
                            if (tag) {
                                await this.fhirService.tags.delete(patient.encounter, tag);
                            }
                        }
                        catch (e) {
                            console.warn(e.message || e);
                        }
                    }
                }

                resolve(result);
            } catch (e) {
                reject(JSON.stringify(e));
            }
        });
    }

    public analyzer: AnalyzerClass = undefined;
}

export interface IAnalyzerJson {
    name: string,
    version: IAnalyzerJsonVersion,
    conditions: IAnalyzerJsonCondition[]
}

export interface IAnalyzerJsonVersion {
    main: string | number,
    sub: string | number,
    minor: string | number
}

export interface IAnalyzerJsonCondition {
    name: string,
    defaultSum: number,
    isRiskField?: string,
    sumRiskField?: string,
    usage: "internal" | "risk",
    calculations: IAnalyzerJsonCalculation[]
}

export interface IAnalyzerJsonCalculation {
    type: "calculation" | "results",
    info?: string,
    index?: number,
    conditions?: IAnalyzerJsonConditionItem[],
    results?: IAnalyzerJsonResult[],
}

export interface IAnalyzerJsonConditionItem {
    if: string,
    sum: number
}

export interface IAnalyzerJsonResult {
    if: string,
    resultSum: string,
    resultText: string,
    isRisk: boolean
}
